import React from 'react';
import { graphql } from 'gatsby';
import parseFlexibleContent from '../utils/parse-flexible-content';
import renderFlexibleContent from '../utils/render-flexible-content';
import { SINGLE, MULTIPLE } from '../utils/constants';
import Layout from '../components/layout';
import Seo from '../components/seo';
import PageHero from '../components/molecules/page-hero';
import TextImageAside from '../components/molecules/text-image-aside';
import FeaturedContent from '../components/molecules/featured-content';
import FeaturedContentBanner from '../components/molecules/featured-content-banner';
import LatestPosts from '../components/molecules/latest-posts';
import TestimonialSection from '../components/molecules/testimonial-section';
import FormRenderer from '../components/organisms/form-renderer';
import AdditionalContent from '../components/molecules/additional-content';

const modules = {
  Hero: PageHero,
  TextImageAside,
  FeaturedContent,
  Testimonial: TestimonialSection,
  FeaturedContentBanner,
  LatestBlogPosts: LatestPosts,
  Form: FormRenderer,
  AdditionalContent,
};

const Homepage = ({ data }) => {
  const flexibleContent = parseFlexibleContent(data.page.acf.flexible_content_page);

  const injectData = {
    LatestBlogPosts: {
      items: data.latestPosts.edges,
    },
  };

  const embeds = [
    {
      key: 'testimonial',
      type: MULTIPLE,
    },
    {
      key: 'form',
      type: SINGLE,
    },
  ];

  return (
    <Layout>
      <Seo fallbackTitle={data.page.title} title={data.page.yoast.title} description={data.page.yoast.metadesc} />
      {renderFlexibleContent(flexibleContent, modules, injectData, embeds)}
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    page: wordpressPage(wordpress_id: { eq: 11 }) {
      title
      yoast {
        title
        metadesc
      }
      acf {
        flexible_content_page {
          module: __typename
          ... on WordPressAcf_hero {
            title
            background_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1280) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            body
          }
          ... on WordPressAcf_text_image_aside {
            image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 440) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            title
            body
            call_to_action_text
            call_to_action_link
          }
          ... on WordPressAcf_latest_blog_posts {
            title
          }
          ... on WordPressAcf_testimonial {
            testimonial {
              wordpress_id
              acf {
                attribution
                body
              }
            }
          }
          ... on WordPressAcf_additional_content {
            title
            body
            no_background
          }
          ... on WordPressAcf_form {
            form {
              wordpress_id
              acf {
                title
                subtitle
                introduction
                cta_button_text
                note
                mailchimp_url
                mailchimp_user_id
                mailchimp_list_id
                success_message
                generic_error_message
                already_subscribed_error_message
                background_image {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 640) {
                        ...GatsbyImageSharpSizes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    latestPosts: allWordpressPost(limit: 3) {
      edges {
        node {
          id
          title
          content
          excerpt
          slug
          date(formatString: "D MMMM YYYY")
          categories {
            name
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                sizes(maxWidth: 640) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Homepage;
